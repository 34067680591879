import { type CountryOptionsObject } from "../../translation-keys/country-option-keys.js";

const FormCountryOptions: CountryOptionsObject = {
  "United States": "États-Unis",
  "United Kingdom": "Royaume-Uni",
  Canada: "Canada",
  Afghanistan: "Afghanistan",
  "Aland Islands": "Îles Åland",
  Albania: "Albanie",
  Algeria: "Algérie",
  "American Samoa": "Samoa américaines",
  Andorra: "Andorre",
  Angola: "Angola",
  Anguilla: "Anguilla",
  Antarctica: "Antarctique",
  "Antigua and Barbuda": "Antigua-et-Barbuda",
  Argentina: "Argentine",
  Armenia: "Arménie",
  Aruba: "Aruba",
  Australia: "Australie",
  Austria: "Autriche",
  Azerbaijan: "Azerbaïdjan",
  Bahamas: "Bahamas",
  Bahrain: "Bahreïn",
  Bangladesh: "Bangladesh",
  Barbados: "Barbade",
  Belarus: "Biélorussie",
  Belgium: "Belgique",
  Belize: "Belize",
  Benin: "Bénin",
  Bermuda: "Bermudes",
  Bhutan: "Bhoutan",
  "Bolivia, Plurinational State of": "Bolivie",
  "Bonaire, Sint Eustatius and Saba": "Bonaire, Saint-Eustache et Saba",
  "Bosnia and Herzegovina": "Bosnie-Herzégovine",
  Botswana: "Botswana",
  "Bouvet Island": "Île Bouvet",
  Brazil: "Brésil",
  "British Indian Ocean Territory": "Territoire britannique de l'océan Indien",
  "Brunei Darussalam": "Brunei Darussalam",
  Bulgaria: "Bulgarie",
  "Burkina Faso": "Burkina Faso",
  Burundi: "Burundi",
  Cambodia: "Cambodge",
  Cameroon: "Cameroun",
  "Cape Verde": "Cap-Vert",
  "Cayman Islands": "Îles Caïmans",
  "Central African Republic": "République centrafricaine",
  Chad: "Tchad",
  Chile: "Chili",
  China: "Chine",
  "Chinese Taipei": "Taipei chinois",
  "Christmas Island": "Île Christmas",
  "Cocos (Keeling) Islands": "Îles Cocos",
  Colombia: "Colombie",
  Comoros: "Comores",
  Congo: "Congo",
  "Congo, Democratic Republic": "République démocratique du Congo",
  "Cook Islands": "Îles Cook",
  "Costa Rica": "Costa Rica",
  "Cote d'Ivoire": "Côte d'Ivoire",
  Croatia: "Croatie",
  Cuba: "Cuba",
  Curaçao: "Curaçao",
  Cyprus: "Chypre",
  "Czech Republic": "République tchèque",
  Denmark: "Danemark",
  Djibouti: "Djibouti",
  Dominica: "Dominique",
  "Dominican Republic": "République dominicaine",
  "East Timor": "Timor-Oriental",
  Ecuador: "Équateur",
  Egypt: "Égypte",
  "El Salvador": "El Salvador",
  "Equatorial Guinea": "Guinée équatoriale",
  Eritrea: "Érythrée",
  Estonia: "Estonie",
  Ethiopia: "Éthiopie",
  "Falkland Islands (Malvinas)": "Îles Falkland (Malouines)",
  "Faroe Islands": "Îles Féroé",
  Fiji: " Fidji",
  Finland: "Finlande",
  France: "France",
  "French Guiana": "Guyane française",
  "French Polynesia": "Polynésie française",
  "French Southern Territories": "Terres australes et antarctiques françaises",
  Gabon: "Gabon",
  Gambia: "Gambie",
  Georgia: "Géorgie",
  Germany: "Allemagne",
  Ghana: "Ghana",
  Gibraltar: "Gibraltar",
  Greece: "Grèce",
  Greenland: "Groenland",
  Grenada: "Grenade",
  Guadeloupe: "Guadeloupe",
  Guam: "Guam",
  Guatemala: "Guatemala",
  Guernsey: "Guernesey",
  Guinea: "Guinée",
  "Guinea-Bissau": "Guinée-Bissau",
  Guyana: "Guyane",
  Haiti: "Haïti",
  "Heard Island and McDonald Islands": "Îles Heard et McDonald",
  "Holy See (Vatican City State)": "Saint-Siège (État de la Cité du Vatican)",
  Honduras: "Honduras",
  "Hong Kong": "Hong Kong",
  Hungary: "Hongrie",
  Iceland: "Islande",
  India: "Inde",
  Indonesia: "Indonésie",
  "Iran, Islamic Republic of": "Iran",
  Iraq: "Irak",
  Ireland: "Irlande",
  "Isle of Man": "Île de Man",
  Israel: "Israël",
  Italy: "Italie",
  Jamaica: "Jamaïque",
  Japan: "Japon",
  Jersey: "Jersey",
  Jordan: "Jordanie",
  Kazakhstan: "Kazakhstan",
  Kenya: "Kenya",
  Kiribati: "Kiribati",
  "Korea, Democratic People's Rep":
    "Corée (République populaire démocratique de)",
  "Korea, Republic of": "Corée (République de)",
  Kuwait: "Koweït",
  Kyrgyzstan: "Kirghizstan",
  "Lao People's Democratic Republic": "Lao, République démocratique populaire",
  Latvia: "Lettonie",
  Lebanon: "Liban",
  Lesotho: "Lesotho",
  Liberia: "Liberia",
  "Libyan Arab Jamahiriya": "Libye",
  Liechtenstein: "Liechtenstein",
  Lithuania: "Lituanie",
  Luxembourg: "Luxembourg",
  Macau: "Macao",
  "Macedonia, former Yugoslav Rep":
    "Macédoine (ancienne République yougoslave de)",
  Madagascar: "Madagascar",
  Malawi: "Malawi",
  Malaysia: "Malaisie",
  Maldives: "Maldives",
  Mali: "Mali",
  Malta: "Malte",
  "Marshall Islands": "Îles Marshall",
  Martinique: "Martinique",
  Mauritania: "Mauritanie",
  Mauritius: "Maurice",
  Mayotte: "Mayotte",
  Mexico: "Mexique",
  "Micronesia, Federated States of": "Micronésie (États fédérés)",
  "Moldova, Republic of": "Moldavie (République de)",
  Monaco: "Monaco",
  Mongolia: "Mongolie",
  Montenegro: "Monténégro",
  Montserrat: "Montserrat",
  Morocco: "Maroc",
  Mozambique: "Mozambique",
  Myanmar: "Myanmar",
  Namibia: "Namibie",
  Nauru: "Nauru",
  Nepal: "Népal",
  Netherlands: "Pays-Bas",
  "Netherlands Antilles": "Antilles néerlandaises",
  "New Caledonia": "Nouvelle-Calédonie",
  "New Zealand": "Nouvelle-Zélande",
  Nicaragua: "Nicaragua",
  Niger: "Niger",
  Nigeria: "Nigeria",
  Niue: "Niue",
  "Norfolk Island": "Île Norfolk",
  "Northern Mariana Islands": "Îles Mariannes du Nord",
  Norway: "Norvège",
  Oman: "Oman",
  Pakistan: "Pakistan",
  Palau: "Palaos",
  "Palestinian Territory, Occupied": "Territoire palestinien (occupé)",
  Panama: "Panama",
  "Papua New Guinea": "Papouasie-Nouvelle-Guinée",
  Paraguay: "Paraguay",
  Philippines: "Philippines",
  Pitcairn: "Îles Pitcairn",
  Poland: "Pologne",
  Portugal: "Portugal",
  "Puerto Rico": "Porto Rico",
  Qatar: "Qatar",
  Reunion: "Réunion",
  Romania: "Roumanie",
  "Russian Federation": "Russie (Fédération de)",
  Rwanda: "Rwanda",
  "Saint Barthélemy": "Saint-Barthélemy",
  "Saint Kitts and Nevis": "Saint-Christophe-et-Niévès",
  "Saint Lucia": "Sainte-Lucie",
  "Saint Martin (French part)": "Saint-Martin (France)",
  "Saint Pierre and Miquelon": "Saint-Pierre-et-Miquelon",
  "Saint Vincent and the Grenadines": "Saint-Vincent-et-les-Grenadines",
  Samoa: "Samoa",
  "San Marino": "Saint-Marin",
  "Sao Tome and Principe": "Sao Tomé-et-Principe",
  "Saudi Arabia": "Arabie Saoudite",
  Senegal: "Sénégal",
  Serbia: "Serbie",
  "Serbia and Montenegro": "Serbie-et-Monténégro",
  Seychelles: "Seychelles",
  "Sierra Leone": "Sierra Leone",
  Singapore: "Singapour",
  "Sint Maarten (Dutch part)": "Sint-Maarten (Pays-Bas)",
  Slovakia: "Slovaquie",
  Slovenia: "Slovénie",
  "Solomon Islands": "Îles Salomon",
  Somalia: "Somalie",
  "South Africa": "Afrique du Sud",
  "South Georgia/South Sandwich": "Îles Géorgie du Sud et Sandwich du Sud",
  "South Sudan": "Sud Soudan",
  Spain: "Espagne",
  "Sri Lanka": "Sri Lanka",
  "St Helena, Ascension, Tristan":
    "Sainte-Hélène, Ascension et Tristan da Cunha",
  Sudan: "Soudan",
  Suriname: "Suriname",
  "Svalbard and Jan Mayen": "Svalbard et Jan Mayen",
  Swaziland: "Swaziland",
  Sweden: "Suède",
  Switzerland: "Suisse",
  "Syrian Arab Republic": "Syrie (République arabe syrienne)",
  Tajikistan: "Tadjikistan",
  "Tanzania, United Republic of": "Tanzanie (République unie de)",
  Thailand: "Thaïlande",
  "Timor-Leste": "Timor-Oriental",
  Togo: "Togo",
  Tokelau: "Tokélaou",
  Tonga: "Tonga",
  "Trinidad and Tobago": "Trinité-et-Tobago",
  Tunisia: "Tunisie",
  Turkey: "Turquie",
  Turkmenistan: "Turkménistan",
  "Turks and Caicos Islands": "Îles Turks-et-Caïcos",
  Tuvalu: "Tuvalu",
  Uganda: "Ouganda",
  Ukraine: "Ukraine",
  "United Arab Emirates": "Émirats arabes unis",
  Uruguay: "Uruguay",
  "US Minor Outlying Islands": "Îles mineures éloignées des États-Unis",
  Uzbekistan: "Ouzbékistan",
  Vanuatu: "Vanuatu",
  "Venezuela, Bolivarian Republic of": "Venezuela",
  "Viet Nam": "Viêt Nam",
  "Virgin Islands (U.S.)": "Îles Vierges américaines",
  "Virgin Islands, British": "Îles Vierges britanniques",
  "Wallis and Futuna": "Wallis-et-Futuna",
  "Western Sahara": "Sahara occidental",
  Yemen: "Yémen",
  Zambia: "Zambie",
  Zimbabwe: "Zimbabwe",
};

export default FormCountryOptions;
